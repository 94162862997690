/** * 关于我们  */
<template>
  <v-card flat class="section-box">
    <v-banner
        single-line
        height="300"
        class="banner"
      >
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/bannerAbout400.jpg"
        height="100%"
        :transition="false">
      </v-img>
    </v-banner> 
    <v-card
      class="warp-1 warp-box"
      max-width="1000"
      flat
    >
      <div class="warp-1-con">
        <div>
          <img src="https://h5.ophyer.cn/official_website/other/about-img-1.png" />
        </div>
        <v-card
          flat
        >
          <p>
            飞天云动是中国元宇宙场景应用层（或AR/VR内容及服务市场）的领先供应商，并已开始建立我们自己的元宇宙平台。我们于中国的AR/VR 内容及服务市场排名第一，于2020年占市场份额的2.3%。我们亦在中国的 AR/VR 服务市场中排名第一，手2020年占市场份额的 12.6%。
          </p>
          <p>
            公司凭借自研的 AR/VR 引擎、Al行为算法、云计算等技术能力，布局元宇宙生态，为娱乐、互联网、电商、房产、文旅、教育、金融、房地产、汽车、直播、技术及保健等各行业企业实现数字化升级及业务扩充赋能。
          </p>
          <p>
            我们的使命是凭借 AR/VR 技术打破现实世界与虛拟世界之间的次元壁垒，推动元宇宙发展和成长
          </p>
        </v-card>
      </div>
    </v-card>
    <v-card
      class="warp-2 warp-box"
      flat
    >
      <div class="public-title">
        <p>DEVELOPMENT PATH</p>
        <div>
          <span>发展历程</span>
        </div>
      </div>
      <div class="warp-stepper">
        <ul class="stepper-tab">
          <li
            v-for="(v, k) in data.warp2"
            :key="k"
            :class="{ checked: v.id == checked }"
            @click="checked = v.id"
          >
            <span>{{ v.year }}</span>
          </li>
        </ul>
        <ul class="stepper-item">
          <span>{{ data.warp2[checked-1].year }}</span>
          <li v-for="(v, k) in data.warp2[checked-1].text" :key="k">
            {{ v }}
          </li>
        </ul>
      </div>

    </v-card>
    <v-card
      class="warp-3 warp-box"
      max-width="1200"
      flat
    >
      <div class="public-title">
        <p>COMPANY HONOR</p>
        <div>
          <span>公司荣誉</span>
        </div>
      </div>
      <!-- <v-card flat class="box">
        <v-card flat class="con">
          <div class="con-d">
            <img src="@/assets/images/cc.jpg" />
          </div>
        </v-card>
        <v-card flat class="con">
          <div class="con-t">
            <img src="@/assets/images/cc.jpg" />
          </div>
          <div class="con-t">
            <img src="@/assets/images/cc.jpg" />
          </div>
        </v-card>
        <v-card flat class="con">
          <div class="con-d">
            <img src="@/assets/images/cc.jpg" />
          </div>
        </v-card>
        <v-card flat class="con">
          <div class="con-t">
            <img src="@/assets/images/cc.jpg" />
          </div>
          <div class="con-t">
            <img src="@/assets/images/cc.jpg" />
          </div>
        </v-card>
      </v-card> -->
      <div class="swiper-box">

      <swiper ref="mySwiper" :options="swiperOption">
        <swiper-slide>
          <div class="con-d">
            <img @click="hanldDialogImg($event)" src="https://h5.ophyer.cn/official_website/other/about-jb-1.jpg" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="con">
            <div class="con-t">
              <img @click="hanldDialogImg($event)" src="https://h5.ophyer.cn/official_website/other/about-jb-5.jpg">
            </div>
            <div class="con-t">
              <img @click="hanldDialogImg($event)" src="https://h5.ophyer.cn/official_website/other/about-jb-6.jpg">
            </div>
          </div>
        </swiper-slide>
 
        <swiper-slide>
          <div class="con">
            <div class="con-t">
              <img @click="hanldDialogImg($event)" src="https://h5.ophyer.cn/official_website/other/about-jb-7.jpg">
            </div>
            <div class="con-t">
              <img @click="hanldDialogImg($event)" src="https://h5.ophyer.cn/official_website/other/about-jb-8.jpg">
            </div>
          </div>
        </swiper-slide>
               <swiper-slide>
          <div class="con-d">
            <img @click="hanldDialogImg($event)" src="https://h5.ophyer.cn/official_website/other/about-jb-2.jpg" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="con-d">
            <img @click="hanldDialogImg($event)" src="https://h5.ophyer.cn/official_website/other/about-jb-3.jpg" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="con">
            <div class="con-t">
              <img @click="hanldDialogImg($event)" src="https://h5.ophyer.cn/official_website/other/about-jb-9.jpg">
            </div>
            <div class="con-t">
              <img @click="hanldDialogImg($event)" src="https://h5.ophyer.cn/official_website/other/about-jb-10.jpg">
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="con-d">
            <img @click="hanldDialogImg($event)" src="https://h5.ophyer.cn/official_website/other/about-jb-4.jpg" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="con">
            <div class="con-t">
              <img @click="hanldDialogImg($event)" src="https://h5.ophyer.cn/official_website/other/about-jb-11.jpg">
            </div>
            <div class="con-t">
              <img @click="hanldDialogImg($event)" src="https://h5.ophyer.cn/official_website/other/about-jb-12.jpg">
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-button-next" slot="button-next"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
      </swiper>
      </div>

    </v-card>
    <v-card
      class="warp-4 warp-box"
      flat
      max-width="1200"
    >
      <div class="public-title">
        <p>CORE MEMBER</p>
        <div>
          <span>核心成员</span>
        </div>
      </div>
      <v-container fluid>
        <v-row dense>
          <v-col
            v-for="item in data.warp4"
            :key="item.title"
            :cols="4"
          >
            <v-card flat class="box" :style="{backgroundImage: 'url('+item.img+')'}">
              <div>
                <p>{{ item.title }}</p>
                <p>{{ item.position }}</p>
                <p>{{ item.text }}</p>
              </div>
              <div>
                <p>{{ item.title }}</p>
                <p>{{ item.position }}</p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card
      class="warp-5 warp-box"
      flat
      max-width="1200"
    >
      <div class="public-title">
        <p>CONTACT US</p>
        <div>
          <span>联系我们</span>
        </div>
      </div>
      <v-card flat class="box">
        <img src="@/assets/login/logo_bai.png" />
        <div class="d1">
          <p>北京飞天云动科技股份有限公司</p>
          <p>北京市朝阳区广渠路3号竞园艺术中心商8</p>
        </div>
        <div class="d2">010-63037996</div>
      </v-card>
      <v-card flat class="box2">
        <div class="left">
          <div>
            <p>商务联系</p>
            <p>yizhe.han@ophyer.com</p>
          </div>
          <div>
            <p>广告合作</p>
            <p>yizhe.han@ophyer.com</p>
          </div>
          <div>
            <p>求职联系</p>
            <p>xiaodan.lan@ophyer.com</p>
          </div>
        </div>
        <el-amap class="el-amap" :amap-manager="amapManager" :zoom="zoom" :center="center" :vid="'amap-vue'">
          <el-amap-marker :position="center"></el-amap-marker>
        </el-amap>
      </v-card>
    </v-card>
    <div class="dialogImg" v-show="dialogImg"  @click.stop="dialogImg = false">
      <transition name="fade" mode="out-in">
        <div class="content-tryout">
          <v-img
            contain
            :src="dialogImgUrl"
            height="100%"
          >
            <v-card-title class="justify-end pb-0 close-btn" @click.stop="dialogImg = false">
              <img width="11px" height="12px" src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-clone.png" alt="">
            </v-card-title>
          </v-img>
        </div>
      </transition>
    </div>
  </v-card>
</template>

<script>
import textData from '@/static/textData.js'
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/css/swiper.css";
import { AMapManager } from "vue-amap";
export default {
  name: 'About',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    let self = this;
    return {
      dialogImg: false,
      dialogImgUrl: '',
      amapManager: null,
      center: [116.51097, 39.89401],
      markers: [],
      zoom: 17,
      e1: 1,
      data: textData.About,
      checked: 10,
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 25,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // },
        prevButton:'.swiper-button-prev',
        nextButton:'.swiper-button-next',
      },
      plugin: [
        {
          // 定位
          showMarker: false, //定位成功后在定位到的位置显示点标记，默认：true
          pName: 'Geolocation',
          events: {
            init(o) {
              // o是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  // 设置经度
                  self.lng = result.position.lng
                  // 设置维度
                  self.lat = result.position.lat
                  self.markers.push([116.51097, 39.89401]);
                }
              });
            }
          }
        },
      ]
      
    }
  },
  mounted() {
    this.amapManager = new AMapManager();
  },
  computed: {
    //  
  },
  created() {
    // this.swiper.slideTo(3, 1000, false)
  },
  methods: {
    hanldDialogImg(e) {
      console.log(e)
      console.log(e.target.currentSrc)
      this.dialogImgUrl = e.target.currentSrc
      // this.dialogImg = true
    }

  }

}
</script>

<style lang="scss" scoped>
.section-box {
  
}

.warp-box {
  margin: 0 auto;
  border: none;
}
.warp-1 {
  max-width: 1200px !important;
  margin-top: 50px;
  height: 470px;
  .warp-1-con {
    display: flex;
    // align-items: center;
    img {
      width: 460px !important;
      height: 470px !important;
    }
    .v-card {
      height: 470px;
      padding-top: 70px;
    }
    p {
      min-width: 500px;
      margin-left: 50px;
      line-height: 26px;
      margin-bottom: 40px;
    }
  }
}

.warp-2 {
  margin: 50px auto;
  width: 1200px;
  .warp-stepper {
    margin-left: 100px;
    .stepper-tab {
      width: 1200px;
      margin: 100px auto;
      display: flex;
      align-items: center;
      li {
        width: 16px;
        height: 16px;
        border: 2px solid #C9C9C9;
        border-radius: 50%;
        margin-right: 92px;
        position: relative;
        cursor: pointer;
        transition: background-color .5s linear 0s;
        span {
          position: absolute;
          top: -85px;
          left: -50px;
          width: 110px;
          height: 34px;
          background: #FFFFFF;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 20px;
          font-size: 20px;
          font-weight: 100 !important;
          font-family: unset;
          color: #333333;
          line-height: 30px;
          text-align: center;
          transition: background-color .5s linear 0s;
          &::before {
            content: "";
            width: 2px;
            height: 50px;
            background: linear-gradient(#cccccc, #ffffff);
            position: absolute;
            bottom: -50px;
            left: 50%;
          }
        }
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: 14px;
          width: 92px;
          height: 1.5px;
          background: #cccccc;
          cursor: default;
        }
        &:nth-child(1)::before {
          display: none;
        }
        &:nth-child(even)  {
          font-size: 60px;
          span {
            position: absolute;
            top: 67px;
            &::before {
              content: "";
              width: 2px;
              height: 54px;
              background: linear-gradient( #ffffff, #cccccc);
              position: absolute;
              top: -54px;
              left: 50%;
              
            }
          }
        }
      }
      .checked {
        border: 2px solid #3579ff !important;
        background: #3579ff;
        span {
          background: #3579FF;
          color: #fff;
          &::before {
            background: #3579ff !important;
          }
        }
        &::after {
          content: "";
          width: 30px;
          height: 30px;
          background: rgba(38, 136, 220,0.2);;
          border-radius: 50%;
          position: absolute;
          bottom: -50%;
          left: 50%;
          transition: unset;
          transform: translate(-50%, 10%);
        }
      }
    }
    
    .stepper-item {
      width: 1100px;
      height: 192px;
      border-radius: 30px;
      padding: 0 60px;
      background: skyblue;
      background: linear-gradient(#fefefe, #f4f5f7);
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: center;
      position: relative;
      li {
        width: 50%;
        height: 40px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #333333;
        text-indent: -10px;
        position: relative;
        padding-right: 30px;
      }
      span {
        position: absolute;
        line-height: 105px;
        font-size: 105px;
        font-weight: 600;
        letter-spacing: 2px;
        mask-image:-webkit-gradient(linear, 0 0, 0 bottom, from(#f3f2f2), to(rgba(0, 0, 255, 0)));
        color: #f3f2f2;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        user-select: none;
      }
    }
  }
}

.warp-3 {
  // .box {
  //   display: flex;
  //   justify-content: space-between;
    
  // }
  // img {
  //   width: 183px;
  //   width: 131px;
  // }
}

.warp-4 {
  width: 1000px;
  margin-top: 50px;
  .box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    width: 280px;
    height: 360px;
    background-size: cover;
    border-radius: 20px;
    margin:0px auto 50px;
    position: relative;
    //margin: 0 auto;
    div:nth-child(1) {
      border-radius: 20px;
      width: 280px;
      height: 100%;
      background: rgba(0, 0, 0, 0.603);
      display: flex;
      flex-direction: column;
      padding: 40px 20px;
      text-align: center;
      color: #FFFFFF;
      font-family: Microsoft YaHei;
      p:nth-child(1) {
        font-size: 22px;
      }
      p:nth-child(2) {
        font-size: 16px;
        margin-bottom: 20px;
      }
      p:nth-child(3) {
        font-size: 14px;
        line-height: 25px;
        text-align: start;
      }
      display: none;
    }
    div:nth-child(2) {
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: flex-end;
      margin-bottom: 25px;
      p:nth-child(1) {
        font-size: 20px;
        font-family: Microsoft YaHei;
        color: #FFFFFF;
        font-weight: 400;
      }
      p:nth-child(2) {
       font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
    &:hover {
      div:nth-child(2) {
        //display: none;
      }
      div:nth-child(1) {
        //display: block;
      }
    }
  }
}

.warp-5 {
  margin: -30px auto 60px;
  .box {
    width: 1040px;
    margin: 0 auto;
    height: 120px;
    background: url('https://h5.ophyer.cn/official_website/other/about-bg1.jpg');
    border-radius: 30px !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    
    img {
      height: 61px;
      border-top-left-radius: unset !important;
      border-top-right-radius: unset !important;
    }
    .d1 {
      height: 50px;
      p:nth-child(1) {
        font-size: 24px;
        font-weight: 400;
        color: #FFFFFF;
      }
      p:nth-child(2) {
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
    .d2 {
      font-size: 30px;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
  .box2 {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 60px;
      p:nth-child(1) {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 15px;
      }
      p:nth-child(2) {
        margin-top: -10px;
        font-size: 16px;
      }
    }
    img {
      width: 800px;
      height: 280px;
      border-radius: 30px !important;
    }
  }
}

.text-center {
  justify-content: center;
}

.swiper-box {
  width: 1200px;
  position: relative;
  .swiper-button-next {
    // right: -50px;
    display: none;
  }
  .swiper-button-prev {
    // left: -50px;
    display: none;
  }
  .swiper-container {
    position: unset;
    width: 1080px !important;
    height: 100% !important;
    margin: 0 auto;
    // margin-left: 0px;
  }
  .swiper-slide {
    width: 240px !important; 
    height: 440px;
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 300ms;
      margin-left: 10px;

    .con-d {
      width: 240px;
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      border: 1px solid #E9E9E9;
      border-radius: 30px !important;
      box-shadow: 0px 5px 10px 0px rgba(0, 60, 255, 0.1);
      img {
        width: 100%;
      }
      &:hover {
        border: 1px solid #BED3FF;
        box-shadow: 0px 10px 15px 0px rgba(0, 60, 255, 0.1);

      }
    }
    .con {
      width: 240px;
      height: 400px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
    }
    .con-t {
      width: 240px;
      height: 188px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      border: 1px solid #E9E9E9;
      border-radius: 30px !important;
      box-shadow: 0px 5px 10px 0px rgba(0, 60, 255, 0.1);
      img {
        width: 100%;
      }
      &:hover {
        border: 1px solid #BED3FF;
        box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1);
      }
    }
  }
  &:hover {
    .swiper-button-next {
      // right: -50px;
      display: block;
    }
    .swiper-button-prev {
      // left: -50px;
      display: block;
    }
  }
}


.el-amap {
  width: 800px;
  height: 280px; 
  border-radius: 30px !important;
  background: #fff !important;
  .amap-maps {
    border-radius: 30px !important;
    background: #fff !important;
  }
}
.el-vue-amap {
  background: #fff !important;
}

.dialogImg {
  position: fixed;
  z-index:1001;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.7);
  .content-tryout{
    width: 600px;
    height: 450px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #FFFFFF;
    box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
    .close-btn{
      position: absolute;
      top: 0;
      right: 0;
      color: rgba(0, 0, 0, 0.6) !important;
      cursor: pointer;
    }
  }
}



</style>